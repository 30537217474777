import { Route, Routes } from 'react-router-dom';
import { Navbar, Hero, Projects, Footer } from './components';

import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <main className="main">
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/projects" element={<Projects />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
