import React from 'react';
import './HeroStyles.css';

const Hero = () => {
  return (
    <div className="hero-area">
      <div className="hero-text">
        <h1 className="hello">Hello, I'm Modar.</h1>
        <p>I'm a Frontend developer based in Leipzig, Germany.</p>
      </div>
      <p>I work as a Junior Software Engineer at Spread Group.</p>
    </div>
  );
};

export default Hero;
