export const projects = [
  {
    id: 1,
    image: '/stock.jpg' /* it only has access to public */,
    title: 'Some Project',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui delectus recusandae nemo minus beatae consequatur deleniti harum commodi nam culpa! Optio repellat ut itaque ratione doloremque deleniti veritatis, rerum qui!',
    url: '/',
  },
  {
    id: 2,
    image: '/stock2.jpg',
    title: 'Second Project',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque voluptates ullam corrupti at, commodi ut unde assumenda nulla non numquam cumque nemo tenetur veritatis id reiciendis. Animi expedita tempora officia?',
    url: '/',
  },
  {
    id: 3,
    image: '/stock3.jpg',
    title: 'Third Project',
    description:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad debitis ex ipsum modi dolorem id iste quaerat consequuntur dolore quos minus quod eveniet, dignissimos autem distinctio necessitatibus veniam est cum!',
    url: '/',
  },
  {
    id: 4,
    image: '/stock4.jpg',
    title: 'Another Project',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sunt autquasi vero reprehenderit veniam error natus cupiditate aliquamdoloribus mollitia quibusdam est veritatis, et modi minima sed totamhic dolores!',
    url: '/',
  },
];
