import { RiGithubFill, RiXingFill, RiLinkedinFill } from 'react-icons/ri';

export const links = [
  {
    id: 1,
    url: '/',
    text: 'home',
  },
  {
    id: 4,
    url: '/projects',
    text: 'projects',
  },
];

export const social = [
  {
    id: 1,
    url: 'https://github.com/modarah',
    icon: <RiGithubFill />,
  },
  {
    id: 2,
    url: 'https://www.xing.com/profile/Modar_AboHassoun',
    icon: <RiXingFill />,
  },
  {
    id: 3,
    url: 'https://www.linkedin.com/in/modar-abohassoun/',
    icon: <RiLinkedinFill />,
  },
];
