import React, { useState, useRef, useEffect } from 'react';
import { RiCodeSSlashLine } from 'react-icons/ri';
import { VscChromeClose, VscThreeBars } from 'react-icons/vsc';
import { links } from './data';
import { Link } from 'react-router-dom';
import './NavbarStyles.css';

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const closeMenuRef = useRef(null);
  const linksRef = useRef(null);
  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };
  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`;
    } else {
      linksContainerRef.current.style.height = '0px';
    }
  }, [showLinks]);

  return (
    <>
      <nav>
        <div className="nav-header">
          <RiCodeSSlashLine className="logo" />
          <a href="/">
            <p className="name">Modar</p>
          </a>
          <button className="nav-toggle" onClick={toggleLinks}>
            {showLinks ? <VscChromeClose /> : <VscThreeBars />}
          </button>
        </div>
        <div className="links-container" ref={linksContainerRef}>
          <ul className="links" ref={linksRef}>
            {links.map((link) => {
              const { id, url, text } = link;
              return (
                <li key={id}>
                  <Link to={url}>{text}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
